jQuery(document).ready(function($){
	//Throttle function - prevents too many calls in a certain timeframe

	function throttle(fn, threshhold, scope) {
	  threshhold || (threshhold = 250);
	  var last,
	      deferTimer;
	  return function () {
	    var context = scope || this;

	    var now = +new Date,
	        args = arguments;
	    if (last && now < last + threshhold) {
	      // hold on to it
	      clearTimeout(deferTimer);
	      deferTimer = setTimeout(function () {
	        last = now;
	        fn.apply(context, args);
	      }, threshhold);
	    } else {
	      last = now;
	      fn.apply(context, args);
	    }
	  };
	}

	//Shrinks the nav if not at the top of the page.
	$(window).scroll(throttle(function(event){
		if(window.scrollY > 0){
			$('.site-header').addClass('scrolling');
		}else{
			$('.site-header').removeClass('scrolling');
		}
	}, 250))

	if(window.scrollY > 0){
		$('.site-header').addClass('scrolling');
	}else{
		$('.site-header').removeClass('scrolling');
	}


	$mainLinks = $('.site-header .menu-main-menu-container>ul');
	$mainLinksWithChildren = $('.site-header .menu-main-menu-container>ul>li.menu-item-has-children')
	$mainLinksWithChildrenLinks = $('.site-header .menu-main-menu-container>ul>li.menu-item-has-children>a')
	$svgForDropdownLinksString = '<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4141 8.9375H0V7.0625H11.4141L6.16406 1.8125L7.5 0.5L15 8L7.5 15.5L6.16406 14.1875L11.4141 8.9375Z" fill="black"/></svg>';



	//Adjusts structure of dropdown nav
	$mainLinksWithChildren.each(function(){
		$title = "<h3 class='submenu-title'><a href='" + $(this).children('a').eq(0).attr('href') + "'>" + $(this).children('a').eq(0).text() + "</a></h3>";
		
		$closeButtonIcon = '<svg class="d-none d-lg-block close-button-icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"><path d="M1.02459 25L0 23.9754L11.4754 12.5L0 1.02459L1.02459 0L12.5 11.4754L23.9754 0L25 1.02459L13.5246 12.5L25 23.9754L23.9754 25L12.5 13.5246L1.02459 25Z" fill="#1C1B1F"/></svg>'
		// $closeButtonIcon = '<svg class="d-none d-lg-block close-button-icon" width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="3.12109" width="34" height="3" rx="1.5" transform="rotate(45 3.12109 0)" fill="#1C1B1F"/><rect x="26.9998" y="2.12134" width="34" height="3" rx="1.5" transform="rotate(135 26.9998 2.12134)" fill="#1C1B1F"/></svg>'
		$closebuttonContent = '<div class="d-lg-none mobile-content"><strong>Main Menu</strong></div>'
		$(this).append("<div class='dropdown-wrapper'><div class='container'><div class='row title-row'><div class='col-xl-10 offset-xl-1'></div></div><div class='row menu-row'><div class='col-xl-8 offset-xl-1'></div></div></div>");
		// $(this).children('.dropdown-menu').addClass('col-lg-12 col-xl-10 offset-xl-1');
		$(this).find('.dropdown-wrapper .menu-row>div').prepend($(this).children('.dropdown-menu'));
		$(this).find('.dropdown-wrapper .title-row>div').prepend($title);
		$(this).find('.dropdown-wrapper .title-row>div').append('<div class="close-button flex-row"></div>');
		$(this).find('.close-button').append($svgForDropdownLinksString);
		$(this).find('.close-button').append($closebuttonContent);
		$(this).find('.close-button').append($closeButtonIcon);

		//Close button controls
		$(this).find('.close-button').click(function(event){
			event.stopPropagation();
			$mainLinksWithChildren.each(function(){
				if(window.innerWidth < 1350){
					$('.site-header .menu-main-menu-container').removeClass('slid');
					$(this).removeClass('menu-active');
				}else{
					$(this).removeClass('menu-active');
				}
				
			})
			$dropdownLinks.each(function(){
				$(this).parent().removeClass('menu-active');
			})
			$mainLinks.removeClass('submenu-active');
			$('.site-header').removeClass('menu-open');
		})

	})


	//Open dropdown menu
	$mainLinksWithChildrenLinks.each(function(){
		$(this).append($svgForDropdownLinksString);
		$(this).on('click', function(event){
			event.preventDefault();
			if(window.innerWidth < 1350){

				$('.site-header .menu-main-menu-container').addClass('slid');
				$mainLinksWithChildren.each(function(){
					$(this).removeClass('menu-active')
				})
				$dropdownLinks.each(function(){
					$(this).parent().removeClass('menu-active');
				})
				$(this).parent().addClass('menu-active');
				$mainLinks.addClass('submenu-active');
			}else{
				if($(this).parent().hasClass('menu-active')){
					$(this).parent().removeClass('menu-active');
					$('.site-header').removeClass('menu-open');
				}else{
					$mainLinksWithChildren.each(function(){
						$(this).removeClass('menu-active')
					})
					$dropdownLinks.each(function(){
						$(this).parent().removeClass('menu-active');
					})
					$(this).parent().addClass('menu-active');
					$('.site-header').addClass('menu-open');
					$mainLinks.addClass('submenu-active');
				}
			}
			
			
		})
	})

	//Open next level of dropdown menu
	$dropdownLinks = $('.site-header .menu-main-menu-container>ul>li.menu-item-has-children>.dropdown-wrapper>.container>.menu-row>div>ul>li.menu-item-has-children>a');
	// $dropdownLinks = $('.site-header .menu-main-menu-container>ul>li.menu-item-has-children>.dropdown-wrapper');
	$dropdownLinks.each(function(){
		$(this).on('click', function(event){
			if(window.innerWidth >= 1350){
				event.preventDefault();
				$dropdownLinks.each(function(){
					$(this).parent().removeClass('menu-active');
				})
				$(this).parent().addClass('menu-active');
			}
		})
	})

	//Add SVG to links
	$allDropdownLinks = $('.site-header .menu-main-menu-container>ul>li.menu-item-has-children>.dropdown-wrapper>.container>.menu-row>div>ul>li>a');
	$allDropdownLinks.each(function(){
		$(this).append($svgForDropdownLinksString)
	})

	//Adjust structure of second level of dropdown
	$dropdownMenuItems = $('.site-header .menu-main-menu-container>ul>li.menu-item-has-children>.dropdown-wrapper>.container>.menu-row>div>ul>li.menu-item-has-children');
	$dropdownMenuItems.each(function(){
		$linkTitle = $(this).children('a').text();
		$linkURL = $(this).children('a').attr('href');
		$listItem = "<div class='parent-link current-page-parent menu-active' itemscope='itemscope' itemtype='https://www.schema.org/SiteNavigationElement'> <a href='" + $linkURL + "' class='dropdown-item' aria-label='View all " + $linkTitle + "'>View all " + $linkTitle + " " + $svgForDropdownLinksString + "</a></div>"
		$wrapper = "<div class='sub-dropdown-wrapper'></div>"
		$(this).append($wrapper);
		$(this).children('.sub-dropdown-wrapper').append($listItem);
		$(this).children('.sub-dropdown-wrapper').append($(this).children('.dropdown-menu'));
	})



});